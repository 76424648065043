<template>
  <div class="page">
    <div class="page__header row justify-content-center">
      <div class="col-12 col-xs-11 page__header__content">
        <h1 class="page__header__title">{{ $t('navSideInspections') }}</h1>
        <!--
        <form id="search"><search-input-gcr name="query" v-model="searchQuery" :placeholder="$t('projectSearch')" /></form>
        -->
      </div>
    </div>

    <div class="page__body row justify-content-center">
      <div class="col-12 col-xs-11 page__body__col">
        <h2>Type something</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec finibus dui. Pellentesque ut hendrerit est, a
          congue metus. Aliquam erat volutpat. Etiam vitae nisi et nulla molestie semper. Duis consectetur nisi eget pretium
          maximus. In hac habitasse platea dictumst. Praesent iaculis arcu odio, sed malesuada urna tincidunt id. Nam lobortis
          nulla suscipit, dictum quam sit amet, commodo lectus. Aenean pharetra augue massa, in scelerisque ex fringilla eget.
          Cras in ligula eros. Nulla facilisi. Mauris blandit, ligula quis aliquet bibendum, metus nulla facilisis risus, sed
          fermentum ipsum nisi a ex. Cras tristique malesuada dui, ut tempus risus hendrerit egestas. Proin in sem pellentesque,
          consequat justo sit amet, gravida dolor.
        </p>
        <p>
          Quisque venenatis tellus eget libero aliquam faucibus. Nunc gravida sit amet quam eget luctus. Integer eleifend lacus
          felis, tristique maximus justo malesuada sed. Etiam pellentesque, enim ut facilisis varius, justo justo varius tellus,
          ac lobortis leo odio id arcu. Sed vel faucibus justo. Nullam consequat orci ac justo volutpat dictum eu quis urna.
          Vestibulum consectetur dictum risus, vitae laoreet tortor congue sed. Mauris posuere dui turpis, egestas semper mauris
          tempus placerat.
        </p>
        <h3>Type something</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec finibus dui. Pellentesque ut hendrerit est, a
          congue metus. Aliquam erat volutpat. Etiam vitae nisi et nulla molestie semper. Duis consectetur nisi eget pretium
          maximus. In hac habitasse platea dictumst. Praesent iaculis arcu odio, sed malesuada urna tincidunt id. Nam lobortis
          nulla suscipit, dictum quam sit amet, commodo lectus. Aenean pharetra augue massa, in scelerisque ex fringilla eget.
          Cras in ligula eros. Nulla facilisi. Mauris blandit, ligula quis aliquet bibendum, metus nulla facilisis risus, sed
          fermentum ipsum nisi a ex. Cras tristique malesuada dui, ut tempus risus hendrerit egestas. Proin in sem pellentesque,
          consequat justo sit amet, gravida dolor.
        </p>
        <div class="row">
          <div class="col-6 col-sm-3">
            <p>
              John Doe<br /><a href="tel: 41855555555">418 555-5555</a><br /><a href="mailto:info@courriel.ca"
                >info@courriel.ca</a
              >
            </p>
          </div>
          <div class="col-6 col-sm-3">
            <p>
              John Doe<br /><a href="tel: 41855555555">418 555-5555</a><br /><a href="mailto:info@courriel.ca"
                >info@courriel.ca</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
require('../utilities/page')

export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '../utilities/config';
.page__header__title {
  &::after {
    background-image: url(../assets/check_circle_gray.svg);
  }
}
</style>
